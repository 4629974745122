import { where } from 'firebase/firestore'
import { useToast } from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import Controller from '@/controllers/base.controller'

export default function useAuthLink() {
  const toast = useToast()

  /**
   * Sends the sign link to the given email.
   *
   * @param {string} email the seller email.
   */
  const sendSignLink = async (email) => {
    try {
      const authLink = await new Controller().getAll(
        ['auth-links'],
        [where('email', '==', email)],
      )

      if (!authLink.size) {
        return
      }

      await store.dispatch('auth/sendSignInLink', {
        email,
        authLinkId: authLink.docs[0].id,
      })

      toast({
        component: ToastificationContent,
        props: {
          title: 'Sign email sent',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (e) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Could not sent the email',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    sendSignLink,
  }
}
