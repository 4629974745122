import { computed, ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import store from '@/store'
import OpticEntity from '@/entities/optic.entity'
import UserController from '@/controllers/user.controller'

export default function useOpticsList() {
  /**
   * Property that defines a reference to the optics table.
   */
  const refOpticListTable = ref(null)

  /**
   * Property that defines the searching string from the optics list.
   *
   * @default ''
   */
  const search = ref('')

  /**
   * Property that defines the column that will be used to sort the
   * table.
   *
   * @default 'id'
   */
  const sortBy = ref('createdAt')

  /**
   * Property that defines whether the sort will be ascending or
   * descending.
   *
   * @default true
   */
  const isSortDirDesc = ref(true)

  /**
   * Property that defines the table current page.
   *
   * @default 1
   */
  const currentPage = ref(1)

  const perPageOptions = [10, 25, 50, 100]
  const perPage = 10

  /**
   * Property that stores the fetched optics.
   *
   * @type {import('@vue/composition-api').ComputedRef<OpticEntity[]>}
   */
  const optics = computed({
    get: () => store.state.optic.optics,
  })

  /**
   * Property that defines the optics list headers.
   */
  const tableColumns = computed(() => {
    return [
      {
        key: 'nameFantasy',
        label: i18n.t('admin.labs.listLabs.opticHeaders.name'),
        sortable: true,
      },
      {
        key: 'nameRegistered',
        label: i18n.t('admin.labs.listLabs.opticHeaders.registeredName'),
        sortable: true,
      },
      {
        key: 'userEmail',
        label: i18n.t('admin.labs.listLabs.opticHeaders.email'),
        sortable: true,
      },
      {
        key: 'qtySellers',
        label: i18n.t('admin.labs.listLabs.opticHeaders.qtySellers'),
        sortable: true,
      },
      {
        key: 'qtyMeasurements',
        label: i18n.t('admin.labs.listLabs.opticHeaders.qtyMeasurements'),
        sortable: true,
      },
      {
        key: 'status',
        label: i18n.t('admin.labs.listLabs.opticHeaders.status'),
        sortable: true,
      },
      {
        key: 'active',
        label: i18n.t('admin.labs.listLabs.opticHeaders.active'),
        sortable: true,
      },
      {
        key: 'createdAt',
        label: i18n.t('admin.labs.listLabs.userHeaders.createdAt'),
        sortable: true,
      },
      {
        key: 'actions',
        label: i18n.t('admin.labs.listLabs.userHeaders.actions'),
        sortable: false,
      },
    ]
  })

  /**
   * Deletes an optic from the database.
   *
   * @param {string} id the optic id.
   */
  const deleteOptic = async (id, company, email, parent) => {
    try {
      let path = parent ? `${parent}/` : ''

      path += `optics/${id}`
      await store.dispatch('optic/deleteOptic', {
        path,
        userId: store.state.auth.userId,
      })
      store.dispatch('optic/fetchOptics', store.state.auth.user.adminDocRef)

      const emailData = {
        company: company,
        toEmail: email,
        subject: 'Acesso Bloqueado',
        name: 'Lensxpert',
        fromEmail: '',
      }
      await new UserController().sendAccountBlockedEmail(emailData)
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Restores an optic from the database.
   *
   * @param {string} id the optic id.
   */
  const restoreOptic = async (company, email, id, parent) => {
    try {
      let path = parent ? `${parent}/` : ''

      path += `optics/${id}`
      await store.dispatch('optic/restoreOptic', path)
      store.dispatch('optic/fetchOptics', parent)

      const emailData = {
        company: company,
        toEmail: email,
        subject: 'Acesso Restaurado!',
        name: 'Lensxpert',
        fromEmail: '',
      }
      await new UserController().sendAccountReactivatedEmail(emailData)
    } catch (e) {
      console.error(e)
    }
  }

  return {
    currentPage,
    isSortDirDesc,
    refOpticListTable,
    search,
    sortBy,
    tableColumns,
    optics,
    deleteOptic,
    restoreOptic,
    perPage,
    perPageOptions,
  }
}
