import { computed, ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import store from '@/store'
import LaboratoryEntity from '../../../entities/laboratory.entity'
import UserController from '@/controllers/user.controller'

export default function useLabsList() {
  /**
   * Property that defines a reference to the labs table.
   */
  const refLabsListTable = ref(null)

  /**
   * Property that defines the searching string from the lab list.
   *
   * @default ''
   */
  const search = ref('')

  /**
   * Property that defines the column that will be used to sort the
   * table.
   *
   * @default 'id'
   */
  const sortBy = ref('createdAt')

  /**
   * Property that defines whether the sort will be ascending or
   * descending.
   *
   * @default true
   */
  const isSortDirDesc = ref(true)

  /**
   * Property that defines the table current page.
   *
   * @default 1
   */
  const currentPage = ref(1)

  const perPageOptions = [10, 25, 50, 100]
  const perPage = 10

  /**
   * Property that stores the fetched labs.
   *
   * @type {import('@vue/composition-api').ComputedRef<LaboratoryEntity[]>}
   */
  const labs = computed({
    get: () => store.state.laboratory.labs,
  })

  /**
   * Property that defines the labs list headers.
   */
  const tableColumns = computed(() => {
    return [
      {
        key: 'nameFantasy',
        label: i18n.t('admin.labs.listLabs.tableHeaders.fantasyName'),
        sortable: true,
      },
      {
        key: 'nameRegistered',
        label: i18n.t('admin.labs.listLabs.tableHeaders.registeredName'),
        sortable: true,
      },
      {
        key: 'userEmail',
        label: i18n.t('admin.labs.listLabs.tableHeaders.email'),
        sortable: true,
      },
      {
        key: 'qtyMeasurements',
        label: i18n.t('admin.labs.listLabs.opticHeaders.qtyMeasurements'),
        sortable: true,
      },
      {
        key: 'status',
        label: i18n.t('admin.labs.listLabs.tableHeaders.status'),
        sortable: true,
      },
      {
        key: 'createdAt',
        label: i18n.t('admin.labs.listLabs.userHeaders.createdAt'),
        sortable: true,
      },
      {
        key: 'actions',
        label: i18n.t('admin.labs.listLabs.userHeaders.actions'),
        sortable: false,
      },
    ]
  })

  /**
   * Deletes a laboratory from the database.
   *
   * @param {string} id the laboratory id.
   */
  const deleteLab = async (company, email, id, parent) => {
    try {
      let path = parent ? `${parent}/` : ''

      path += `laboratories/${id}`
      await store.dispatch('laboratory/deleteLab', {
        path,
        userId: store.state.auth.userId,
      })
      store.dispatch('laboratory/fetchLabs', parent)

      const emailData = {
        company: company,
        toEmail: email,
        subject: 'Acesso Bloqueado',
        name: 'Lensxpert',
        fromEmail: '',
      }
      await new UserController().sendAccountBlockedEmail(emailData)
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Restores a laboratory from the database.
   *
   * @param {string} id the laboratory id.
   */
  const restoreLab = async (company, email, id, parent) => {
    try {
      let path = parent ? `${parent}/` : ''

      path += `laboratories/${id}`
      await store.dispatch('laboratory/restoreLab', path)
      store.dispatch('laboratory/fetchLabs', parent)

      const emailData = {
        company: company,
        toEmail: email,
        subject: 'Acesso Restaurado!',
        name: 'Lensxpert',
        fromEmail: '',
      }
      await new UserController().sendAccountReactivatedEmail(emailData)
    } catch (e) {
      console.error(e)
    }
  }

  return {
    currentPage,
    isSortDirDesc,
    refLabsListTable,
    search,
    sortBy,
    tableColumns,
    labs,
    deleteLab,
    restoreLab,
    perPage,
    perPageOptions,
  }
}
