import { QueryConstraint } from 'firebase/firestore'
import { Store } from 'vuex'

import Controller from '@/controllers/base.controller'
import SoftwarePartnerController from '@/controllers/software-partner.controller'
import SoftwarePartnerEntity from '@/entities/software-partner.entity'

const spController = new SoftwarePartnerController()

export default {
  namespaced: true,
  state: {
    softwarePartners: [],
    loading: false,
    loadingSoftwarePartners: false,
  },
  getters: {},
  mutations: {
    /**
     * Sets the `loading` property.
     *
     * @param {Object} state the software partner state.
     * @param {boolean} data the loading status.
     */
    SET_LOADING(state, data) {
      state.loading = data
    },

    /**
     * Sets the `loadingSoftwarePartners` property.
     *
     * @param {Object} state the software partner state.
     * @param {boolean} data the loading status.
     */
    SET_LOADING_SOFTWARE_PARTNERS(state, data) {
      state.loadingSoftwarePartners = data
    },

    /**
     * Sets the `labs` property.
     *
     * @param {Object} state the software partner state.
     * @param {SoftwarePartnerEntity[]} data an array of software partners.
     */
    SET_SOFTWARE_PARTNERS(state, data) {
      state.softwarePartners = data
    },
  },
  actions: {
    /**
     * Sets a software partner into the database according to the given id.
     *
     * @param {Store} store the vuex store.
     * @param {Object} payload the action payload.
     * @param {string} payload.id the software partner id.
     * @param {SoftwarePartnerEntity} payload.data the software partner data.
     */
    async setSoftwarePartner({ commit }, payload) {
      commit('SET_LOADING', true)

      const { id, data } = payload

      const now = new Date().toISOString()

      data.updatedAt = now

      try {
        if (!id) {
          data.createdAt = now
          return await spController.create(data)
        }

        return await spController.update(new SoftwarePartnerEntity(data), id)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Loads software partners according to the given filters.
     *
     * @param {Store} store the vuex store.
     * @param {QueryConstraint[]} payload the fetch filters.
     */
    async fetchSoftwarePartners({ commit }, payload) {
      commit('SET_LOADING_SOFTWARE_PARTNERS', true)

      try {
        const softwarePartners = await spController.getAll(payload)
        commit('SET_SOFTWARE_PARTNERS', softwarePartners)

        return softwarePartners
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING_SOFTWARE_PARTNERS', false)
      }
    },

    /**
     * Get a software partner from the database.
     *
     * @param {Store} store the vuex store.
     * @param {string} payload the software partner id.
     * @returns a software partner entity.
     */
    async fetchOneSoftwarePartner({ state, commit }, payload) {
      commit('SET_LOADING', true)

      try {
        const softwarePartner =
          state.softwarePartners.find((l) => l.id === payload) ??
          (await spController.getOne(payload))

        if (!softwarePartner) {
          throw new Error('software-partner-not-found')
        }

        return softwarePartner
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Uploads the software partner logo.
     *
     * @param {Object} payload the action payload.
     * @param {string} payload.path the logo path.
     * @param {File} payload.file the logo image.
     */
    async uploadLogo(_, payload) {
      try {
        await new Controller().uploadFile(payload.path, payload.file)
      } catch (e) {
        throw e
      }
    },

    /**
     * Deletes the software partner logo.
     *
     * @param {string} payload the logo path.
     */
    async removeLogo(_, payload) {
      try {
        await new Controller().deleteFile(payload)
      } catch (e) {
        throw e
      }
    },

    /**
     * Soft deletes a software partner from the database.
     *
     * @param {Store} store the vuex store.
     * @param {Object} payload the action payload.
     * @param {string} payload.path the document path.
     * @param {string} payload.userId the user id.
     */
    async deleteSoftwarePartner({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        await spController.softDelete(payload.path, payload.userId)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Restores a software partner from the soft delete.
     *
     * @param {Store} store the vuex store.
     * @param {string} payload the document path.
     */
    async restoreSoftwarePartner({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        await spController.restore(payload)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
