import { computed, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'

import store from '@/store'
import SoftwarePartnerEntity from '../../../entities/software-partner.entity'
import UserController from '@/controllers/user.controller'
import {
  limit,
  orderBy,
  QueryConstraint,
  startAt,
  where,
} from 'firebase/firestore'

export default function useSoftwarePartnersList() {
  const toast = useToast()

  /**
   * Property that defines a reference to the software partners table.
   */
  const refSoftwarePartnersListTable = ref(null)

  /**
   * Property that defines the searching string from the lab list.
   *
   * @default ''
   */
  const search = ref('')

  /**
   * Property that defines the column that will be used to sort the
   * table.
   *
   * @default 'id'
   */
  const sortBy = ref('createdAt')

  /**
   * Property that defines whether the sort will be ascending or
   * descending.
   *
   * @default true
   */
  const isSortDirDesc = ref(true)

  /**
   * Property that defines the table current page.
   *
   * @default 1
   */
  const currentPage = ref(1)

  const perPageOptions = [10, 25, 50, 100]
  const perPage = 10

  /**
   * Fetches all software partners according to the current page.
   */
  const fetchSoftwarePartners = async () => {
    /**
     * @type {QueryConstraint[]}
     */
    const constraints = [
      // startAt((currentPage.value - 1) * perPage.value),
      // limit(1),
      where('nameFantasy', '>=', search.value),
      orderBy('nameFantasy'),
    ]

    // TODO: implement cursor pagination
    const items = await store.dispatch(
      'software-partner/fetchSoftwarePartners',
      constraints,
    )
  }

  /**
   * Property that stores the fetched software partners.
   *
   * @type {import('@vue/composition-api').ComputedRef<SoftwarePartnerEntity[]>}
   */
  const softwarePartners = computed({
    get: () => store.state['software-partner'].softwarePartners,
  })

  /**
   * Property that stores the current loading state.
   *
   * @type {import('@vue/composition-api').ComputedRef<boolean>}
   */
  const loading = computed({
    get: () => store.state['software-partner'].loading,
  })

  /**
   * Property that defines the software partners list headers.
   */
  const tableColumns = computed(() => {
    return [
      {
        key: 'nameFantasy',
        label: i18n.t('admin.labs.listLabs.sellerHeaders.name'),
        sortable: true,
      },
      {
        key: 'nameRegistered',
        label: i18n.t('admin.labs.listLabs.sellerHeaders.registeredName'),
        sortable: true,
      },
      {
        key: 'softwareAdmin',
        label: i18n.t('admin.labs.listLabs.sellerHeaders.softwareAdmin'),
        sortable: true,
      },
      {
        key: 'qtyMeasurements',
        label: i18n.t('admin.labs.listLabs.opticHeaders.qtyMeasurements'),
        sortable: true,
      },
      {
        key: 'status',
        label: i18n.t('admin.labs.listLabs.sellerHeaders.status'),
        sortable: true,
      },
      {
        key: 'createdAt',
        label: i18n.t('admin.labs.listLabs.userHeaders.createdAt'),
        sortable: true,
      },
      {
        key: 'actions',
        label: i18n.t('admin.labs.listLabs.userHeaders.actions'),
        sortable: false,
      },
    ]
  })

  /**
   * Deletes a software partner from the database.
   *
   * @param {string} id the software partner id.
   */
  const deleteSoftwarePartner = async (company, email, id) => {
    try {
      const path = `software-partners/${id}`
      await store.dispatch('software-partner/deleteSoftwarePartner', {
        path,
        userId: store.state.auth.userId,
      })
      await fetchSoftwarePartners()

      const emailData = {
        company: company,
        toEmail: email,
        subject: 'Acesso Bloqueado',
        name: 'Lensxpert',
        fromEmail: '',
      }
      await new UserController().sendAccountBlockedEmail(emailData)
    } catch (e) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('toast.softwarePartnerList.failure'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  /**
   * Restores a software partner from the database.
   *
   * @param {string} id the software partner id.
   */
  const restoreSoftwarePartner = async (company, email, id) => {
    try {
      const path = `software-partners/${id}`
      await store.dispatch('software-partner/restoreSoftwarePartner', path)
      await fetchSoftwarePartners()

      const emailData = {
        company: company,
        toEmail: email,
        subject: 'Acesso Restaurado!',
        name: 'Lensxpert',
        fromEmail: '',
      }
      await new UserController().sendAccountReactivatedEmail(emailData)
    } catch (e) {
      console.error(e)
    }
  }

  return {
    currentPage,
    isSortDirDesc,
    refSoftwarePartnersListTable,
    search,
    sortBy,
    tableColumns,
    softwarePartners,
    deleteSoftwarePartner,
    restoreSoftwarePartner,
    loading,
    fetchSoftwarePartners,
    perPage,
    perPageOptions,
  }
}
