import { QueryConstraint } from 'firebase/firestore'
import { Store } from 'vuex'

import Controller from '@/controllers/base.controller'
import LaboratoryController from '@/controllers/laboratory.controller'
import LaboratoryEntity from '@/entities/laboratory.entity'

const labController = new LaboratoryController()

export default {
  namespaced: true,
  state: {
    labs: [],
    loading: false,
    loadingLabs: false,
  },
  getters: {},
  mutations: {
    /**
     * Sets the `loading` property.
     *
     * @param {Object} state the laboratory state.
     * @param {boolean} data the loading status.
     */
    SET_LOADING(state, data) {
      state.loading = data
    },

    /**
     * Sets the `loadingLabs` property.
     *
     * @param {Object} state the laboratory state.
     * @param {boolean} data the loading status.
     */
    SET_LOADING_LABS(state, data) {
      state.loadingLabs = data
    },

    /**
     * Sets the `labs` property.
     *
     * @param {Object} state the laboratory state.
     * @param {LaboratoryEntity[]} data an array of laboratories.
     */
    SET_LABS(state, data) {
      state.labs = data
    },
  },
  actions: {
    /**
     * Sets a laboratory into the database according to the given id.
     *
     * @param {Store} store the vuex store.
     * @param {Object} payload the action payload.
     * @param {string} payload.id the laboratory id.
     * @param {LaboratoryEntity} payload.data the laboratory data.
     */
    async setLaboratory({ commit }, payload) {
      commit('SET_LOADING', true)

      const { id, data, parent } = payload

      const now = new Date().toISOString()

      data.updatedAt = now

      try {
        if (!id) {
          data.createdAt = now
          return await labController.create(new LaboratoryEntity(data), parent)
        }

        return await labController.update(
          new LaboratoryEntity(data),
          id,
          parent,
        )
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Loads laboratories according to the given filters.
     *
     * @param {Store} store the vuex store.
     * @param {QueryConstraint[]} payload the fetch filters.
     */
    async fetchLabs({ commit }, payload) {
      commit('SET_LOADING_LABS', true)

      try {
        const path = payload ? `${payload}/laboratories` : 'laboratories'

        labController.listenMultiple(path, (labs) => {
          commit('SET_LABS', labs)
        })
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING_LABS', false)
      }
    },

    /**
     * Get a laboratory from the database.
     *
     * @param {Store} store the vuex store.
     * @param {string} payload the laboratory id.
     * @returns a laboratory entity.
     */
    async fetchOneLab({ state, commit }, payload) {
      commit('SET_LOADING', true)

      try {
        const lab = await labController.getOne(payload)

        if (!lab) {
          throw new Error('lab-not-found')
        }

        return lab
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Uploads the laboratory logo.
     *
     * @param {Object} payload the action payload.
     * @param {string} payload.path the logo path.
     * @param {File} payload.file the logo image.
     */
    async uploadLogo(_, payload) {
      try {
        await new Controller().uploadFile(payload.path, payload.file)
      } catch (e) {
        throw e
      }
    },

    /**
     * Deletes the laboratory logo.
     *
     * @param {string} payload the logo path.
     */
    async removeLogo(_, payload) {
      try {
        await new Controller().deleteFile(payload)
      } catch (e) {
        throw e
      }
    },

    /**
     * Deletes a laboratory from the database.
     *
     * @param {Store} store the vuex store.
     * @param {Object} payload the action payload.
     * @param {string} payload.path the document path.
     * @param {string} payload.userId the user id.
     */
    async deleteLab({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        await labController.softDelete(payload.path, payload.userId)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Restores a laboratory from the database.
     *
     * @param {Store} store the vuex store.
     * @param {string} payload the document path.
     */
    async restoreLab({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        await labController.restore(payload)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
