import { QueryConstraint } from 'firebase/firestore'
import { Store } from 'vuex'

import OpticController from '@/controllers/optic.controller'
import OpticEntity from '@/entities/optic.entity'
import Controller from '../../../controllers/base.controller'

const opticController = new OpticController()

export default {
  namespaced: true,
  state: {
    optics: [],
    loading: false,
    loadingOptics: false,
  },
  getters: {},
  mutations: {
    /**
     * Sets the `loading` property.
     *
     * @param {Object} state the optic state.
     * @param {boolean} data the loading status.
     */
    SET_LOADING(state, data) {
      state.loading = data
    },

    /**
     * Sets the `loadingOptics` property.
     *
     * @param {Object} state the optic state.
     * @param {boolean} data the loading status.
     */
    SET_LOADING_OPTICS(state, data) {
      state.loadingOptics = data
    },

    /**
     * Sets the `optics` property.
     *
     * @param {Object} state the optic state.
     * @param {OpticEntity[]} data an array of optics.
     */
    SET_OPTICS(state, data) {
      state.optics = data
    },
  },
  actions: {
    /**
     * Sets an optic into the database according to the given id.
     *
     * @param {Store} store the vuex store.
     * @param {Object} payload the action payload.
     * @param {string} payload.id the optic id.
     * @param {OpticEntity} payload.data the optic data.
     */
    async setOptic({ commit }, payload) {
      commit('SET_LOADING', true)

      const { id, data, parent } = payload

      const now = new Date().toISOString()

      data.updatedAt = now

      try {
        if (!id) {
          data.createdAt = now
          return await opticController.create(new OpticEntity(data), parent)
        }

        return await opticController.update(new OpticEntity(data), id, parent)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Loads optics according to the given filters.
     *
     * @param {Store} store the vuex store.
     * @param {QueryConstraint[]} payload the fetch filters.
     */
    async fetchOptics({ commit }, payload) {
      commit('SET_LOADING_OPTICS', true)

      try {
        const optics = await opticController.getAll(payload)
        commit('SET_OPTICS', optics)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING_OPTICS', false)
      }
    },

    /**
     * Get an optic from the database.
     *
     * @param {Store} store the vuex store.
     * @param {string} payload the optic id.
     * @returns an optic entity.
     */
    async fetchOneOptic({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        const optic = await opticController.getOne(payload)

        if (!optic) {
          throw new Error('optic-not-found')
        }

        return optic
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Uploads the optic logo.
     *
     * @param {Object} payload the action payload.
     * @param {string} payload.path the logo path.
     * @param {File} payload.file the logo image.
     */
    async uploadLogo(_, payload) {
      try {
        await new Controller().uploadFile(payload.path, payload.file)
      } catch (e) {
        throw e
      }
    },

    /**
     * Deletes the optic logo.
     *
     * @param {string} payload the logo path.
     */
    async removeLogo(_, payload) {
      try {
        await new Controller().deleteFile(payload)
      } catch (e) {
        throw e
      }
    },

    /**
     * Soft deletes an optic from the database.
     *
     * @param {Store} store the vuex store.
     * @param {Object} payload the action payload.
     * @param {string} payload.path the document path.
     * @param {string} payload.userId the user id.
     */
    async deleteOptic({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        await opticController.softDelete(payload.path, payload.userId)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Deletes an optic from the database.
     *
     * @param {Store} param0 the vuex store.
     * @param {string} payload the optic document path.
     */
    async hardDeleteOptic({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        await opticController.delete(payload)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Restores an optic from the soft delete.
     *
     * @param {Store} store the vuex store.
     * @param {string} payload the document path.
     */
    async restoreOptic({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        await opticController.restore(payload)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
