<template>
  <b-modal
    hide-header
    v-model="modal"
    ok-variant="danger"
    cancel-variant="outline-secondary"
    :ok-title="$t('alertDialog.ok')"
    :cancel-title="$t('alertDialog.cancel')"
    :ok-only="warnOnly"
    :hide-header-close="true"
    :centered="centered"
    @cancel="close"
    @ok="callback"
  >
    <div class="container">
      <div class="row mb-2 mt-2">
        <h3>
          <b>{{ title }}</b>
        </h3>
      </div>
      <div class="row alert alert-danger" role="alert">
        <div class="col md-1 mb-1 mt-1">
          <div class="d-flex justify-content-center">
            <feather-icon icon="AlertTriangleIcon" size="35" />
          </div>
        </div>
        <div class="col col-md-10 mb-1 mt-1">
          <b v-html="message"></b>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  props: {
    show: {
      type: Boolean,
    },
    warnOnly: {
      type: Boolean,
    },
    centered: {
      type: Boolean,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modal: false,
    }
  },
  watch: {
    show() {
      this.modal = this.show
    },
    modal(value) {
      if (!value) {
        this.$emit('close')
      }
    },
  },
  methods: {
    close() {
      this.modal = false
      this.$emit('close')
    },
    callback() {
      this.modal = false
      this.$emit('action')
    },
  },
}
</script>