<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <div v-if="isSuperAdmin">
          <b-row align-v="end" class="pb-2">
            <!-- Software Partner Filter -->
            <b-col cols="12" lg="4" md="4">
              <div>
                <small>{{ $t('admin.labs.opticsList.softwarePartner') }}</small>
                <v-select
                  v-model="selectedSoftwarePartner"
                  :options="filterSoftwarePartners"
                  :clearable="false"
                  label="nameRegistered"
                />
              </div>
            </b-col>

            <!-- Labs Filter -->
            <b-col cols="12" lg="4" md="4">
              <div>
                <small>{{ $t('admin.labs.opticsList.lab') }}</small>
                <v-select
                  v-model="selectedLab"
                  :options="filterLabs"
                  :clearable="false"
                  label="nameRegistered"
                  :placeholder="$t('admin.labs.opticsList.myData')"
                />
              </div>
            </b-col>

            <b-col>
              <b-button block variant="danger" @click="clearFilter()">
                {{ $t('admin.labs.opticsList.cleanFilter') }}
                <feather-icon icon="XIcon" />
              </b-button>
            </b-col>
          </b-row>

          <b-row align-h="between">
            <!-- Per Page -->
            <b-col
              cols="12"
              lg="3"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>{{ $t('admin.measurements.list.show') }}</label>

              <v-select
                v-model="perPage"
                class="per-page-selector d-inline-block mx-50"
                :options="perPageOptions"
                :clearable="false"
              />

              <label>{{ $t('admin.measurements.list.entries') }}</label>
            </b-col>

            <!-- Search -->
            <b-col cols="12" lg="6" md="6">
              <div class="d-flex align-items-center">
                <b-form-input
                  v-model="search"
                  class="d-inline-block"
                  :placeholder="$t('admin.labs.sellersList.searchBy')"
                />

                <b-button class="ml-1" variant="success" @click="newOptic()">
                  <span class="text-nowrap">{{
                    $t('admin.labs.opticsList.create')
                  }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row v-else align-v="end" align-h="between">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            lg="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('admin.labs.opticsList.show') }}</label>

            <v-select
              v-model="perPage"
              class="per-page-selector d-inline-block mx-50"
              :options="perPageOptions"
              :clearable="false"
            />

            <label>{{ $t('admin.labs.opticsList.entries') }}</label>
          </b-col>

          <!-- Labs Filter -->
          <b-col cols="12" lg="8" md="8"
            ><b-row align-v="end" align-h="end">
              <b-col cols="12" lg="4" md="4">
                <div v-if="isSoftwarePartner">
                  <small>{{ $t('admin.labs.opticsList.lab') }}</small>
                  <v-select
                    v-model="selectedLab"
                    :options="filterLabs"
                    :clearable="false"
                    label="nameRegistered"
                  />
                </div>
              </b-col>

              <!-- Search -->
              <b-col cols="12" :md="6" :class="{ 'p-0': true }">
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="search"
                    class="d-inline-block"
                    placeholder="Search by name..."
                  />

                  <b-button
                    v-if="!isSoftwarePartner"
                    class="ml-1"
                    variant="success"
                    :disabled="$store.getters['auth/inactive']"
                    @click="newOptic()"
                  >
                    <span class="text-nowrap">{{
                      $t('admin.labs.opticsList.create')
                    }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refOpticListTable"
        responsive
        show-empty
        primary-key="id"
        empty-text="No entries"
        class="position-relative"
        :items="filteredItems"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :busy="$store.state.optic.loadingOptics"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" small />

            <strong class="ml-1">{{
              $t('admin.labs.opticsList.loading')
            }}</strong>
          </div>
        </template>

        <template #cell(qtyMeasurements)="data">
          <span>{{
            `${data.item.qtyMeasurements}/${
              data.item.allowedMeasurements || '-'
            }`
          }}</span>
        </template>

        <template #cell(updatedAt)="data">
          <span>{{
            parseDateToString(data.item.updatedAt, 'mm dd yyyy')
          }}</span>
        </template>

        <template #cell(createdAt)="data">
          <span>{{
            parseDateToString(data.item.createdAt, 'mm dd yyyy')
          }}</span>
        </template>

        <template #cell(status)="data">
          <b-badge :variant="getStatusVariant(data.item)">
            <span>{{ getStatusText(data.item) }}</span>
          </b-badge>
        </template>

        <template #cell(active)="data">
          <b-badge :variant="getActiveVariant(data.item.active)">
            <span>{{ getActiveText(data.item.active) }}</span>
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row align-h="end">
            <b-button
              v-if="data.item.status != 'active'"
              size="sm"
              variant="outline-warning"
              :id="`email-${data.item.id}`"
              :disabled="
                $store.getters['auth/inactive'] || !!data.item.deletedAt
              "
              @click="sendSignLink(data.item.userEmail)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-tooltip
              triggers="hover"
              placement="bottom"
              :target="`email-${data.item.id}`"
            >
              <b>{{ $t('tooltips.sendInvitation') }}</b>
            </b-tooltip>

            <b-button
              size="sm"
              class="ml-1"
              variant="outline-secondary"
              :id="`edit-${data.item.id}`"
              :disabled="
                $store.getters['auth/inactive'] || !!data.item.deletedAt
              "
              @click="editOptic(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-tooltip
              triggers="hover"
              placement="bottom"
              :target="`edit-${data.item.id}`"
            >
              <b>{{ $t('admin.labs.opticsList.edit') }}</b>
            </b-tooltip>

            <b-button
              size="sm"
              class="ml-1"
              :id="`delete-${data.item.id}`"
              :variant="
                canRestore(data.item) ? 'outline-success' : 'outline-danger'
              "
              :disabled="
                $store.getters['auth/inactive'] ||
                (data.item.deletedAt &&
                  data.item.deletedBy !== $store.state.auth.userId)
              "
              @click="
                () => {
                  if (data.item.deletedAt) {
                    return restoreOptic(
                      data.item.nameFantasy,
                      data.item.userEmail,
                      data.item.id,
                      $store.state.auth.user.adminDocRef,
                    )
                  }

                  selectedItem = data.item
                  deleteModal = true
                }
              "
            >
              <feather-icon v-if="canRestore(data.item)" icon="RotateCcwIcon" />
              <feather-icon v-else icon="SlashIcon" />
            </b-button>
            <b-tooltip
              triggers="hover"
              placement="bottom"
              :target="`delete-${data.item.id}`"
            >
              <b>{{
                $t(
                  'admin.labs.opticsList.' +
                    (canRestore(data.item) ? 'restore' : 'delete'),
                )
              }}</b>
            </b-tooltip>
          </b-row>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('admin.labs.opticsList.totalOf') }} {{ totalItems }}
              {{ $t('admin.labs.opticsList.entries') }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              :per-page="perPage"
              :total-rows="totalItems"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <AlertDialog
      :message="$t('admin.labs.opticsList.deleteDialog.message')"
      :title="`${$t('admin.labs.opticsList.deleteDialog.title')} ${
        selectedItem.nameFantasy
      }`"
      :id="`confirmation-${selectedItem.id}`"
      :show="deleteModal"
      @action="
        deleteItem(selectedItem)
        selectedItem = {}
      "
      @close="deleteModal = false"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AlertDialog from '@/components/AlertDialog.vue'

import { onUnmounted, ref } from 'vue-demi'
import store from '@/store'

import opticStoreModule from './opticStoreModule'
import useOpticsList from './useOpticsList'
import useAuthLink from '../../auth/useAuthLink'
import { parseDateToString } from '../../../utils/dates'
import RoleEnum from '@/utils/roles'
import labStoreModule from '@/views/sections/labs/labStoreModule'
import useLabsList from '@/views/sections/labs/useLabsList'
import softwarePartnerStoreModule from '@/views/sections/software-partners/softwarePartnerStoreModule'
import useSoftwarePartnersList from '@/views/sections/software-partners/useSoftwarePartnersList'

export default {
  name: 'OpticsList',

  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,

    vSelect,
    AlertDialog,
  },

  data() {
    return {
      deleteModal: false,
      selectedItem: {},

      parseDateToString,
    }
  },

  watch: {
    labs: {
      deep: true,
      handler() {
        if (this.isSoftwarePartner) {
          this.selectedLab = {
            me: true,
            nameRegistered:
              this.user.role === RoleEnum.SOFTWARE_ADMIN
                ? this.$i18n.t('admin.labs.opticsList.myLab')
                : this.$i18n.t('admin.labs.opticsList.myData'),
          }
        } else this.selectedLab = null
      },
    },
    softwarePartners: {
      deep: true,
      handler() {
        if (this.isSuperAdmin) {
          this.selectedSoftwarePartner = {
            me: true,
            nameRegistered: this.$i18n.t('admin.labs.opticsList.myData'),
          }
        } else this.selectedSoftwarePartner = null
      },
    },
    selectedSoftwarePartner(val) {
      if (val != null) {
        this.refetchOptics(val)
        if (val.me) this.refetchLabs({ me: true })
        else this.refetchLabs({ me: false })
      } else this.refetchOptics({ me: true })
    },
    selectedLab(val) {
      if (val != null) this.refetchOptics(val)
      else this.refetchOptics({ me: true })
    },
  },

  computed: {
    filteredItems() {
      return this.optics.filter((el) =>
        el.nameFantasy.toLowerCase().includes(this.search.toLowerCase()),
      )
    },
    totalItems() {
      return this.filteredItems.length || 0
    },
    user() {
      return this.$store.state.auth.user
    },
    isSuperAdmin() {
      return this.user.role === RoleEnum.SUPER_ADMIN ? true : false
    },
    isSoftwarePartner() {
      return this.user.role === RoleEnum.SOFTWARE_ADMIN ? true : false
    },
    filterSoftwarePartners() {
      let items = []
      if (this.isSuperAdmin) {
        items = [
          {
            me: true,
            nameRegistered: this.$i18n.t('admin.labs.opticsList.myData'),
          },
        ].concat(this.softwarePartners.filter((el)=>{
          return el.status == "active"
        }))
      }

      return items
    },
    filterLabs() {
      let items = []
      if (this.isSoftwarePartner) {
        items = [
          {
            me: true,
            nameRegistered:
              this.user.role === RoleEnum.SOFTWARE_ADMIN
                ? this.$i18n.t('admin.labs.opticsList.myLab')
                : this.$i18n.t('admin.labs.opticsList.myData'),
          },
        ].concat(this.labs.filter((el)=>{
          return el.status == "active"
        }))
      } else if (this.isSuperAdmin) {
        items = this.labs.filter((el)=>{
          return el.status == "active"
        })
      }

      return items
    },
  },

  methods: {
    deleteItem(item) {
      let parent = ``
      if (this.user.role === RoleEnum.SUPER_ADMIN) {
        parent = ''
      } else {
        parent = `${this.user.adminDocRef}`
      }

      this.deleteOptic(item.id, item.nameFantasy, item.userEmail, parent)
    },

    restoreItem(item) {
      let parent = ``
      if (this.user.role === RoleEnum.SUPER_ADMIN) {
        parent = ''
      } else {
        parent = `${this.user.adminDocRef}`
      }
      this.restoreOptic(item.id, parent)
    },

    canRestore(item) {
      return item.deletedAt && item.deletedBy === this.$store.state.auth.userId
    },
    clearFilter() {
      this.selectedLab = null
      this.selectedSoftwarePartner = null

      this.refetchOptics({ me: true })
    },
    newOptic() {
      let parent = `${this.user.adminDocRef}`

      this.$router.push({
        name: 'optic-edit',
        params: { id: 'new' },
        query: { p: parent },
      })
    },
    editOptic(item) {
      let parent = ``
      if (this.user.role === RoleEnum.SOFTWARE_ADMIN) {
        parent = `${this.user.adminDocRef}/laboratories/${this.selectedLab.id}`
      } else {
        parent = `${this.user.adminDocRef}`
      }

      this.$router.push({
        name: 'optic-edit',
        params: { id: item.id },
        query: { p: parent },
      })
    },
    async refetchLabs(val) {
      let path = ''
      if (this.isSuperAdmin) {
        if (!val.me) {
          if (this.selectedSoftwarePartner) {
            path = `software-partners/${this.selectedSoftwarePartner.id}`
          }
        }
      }

      this.$store.dispatch('laboratory/fetchLabs', path)
    },
    async refetchOptics(item) {
      let path = ''
      if (item.me) {
        // Get the optics of the software partner directly
        path = this.user.adminDocRef
      } else {
        if (this.isSoftwarePartner) {
          // Get the optics of the lab
          path = `${this.user.adminDocRef}/laboratories/${item.id}/`
        } else if (this.isSuperAdmin) {
          if (this.selectedSoftwarePartner && this.selectedLab) {
            path = `software-partners/${this.selectedSoftwarePartner.id}/laboratories/${this.selectedLab.id}`
          } else if (this.selectedSoftwarePartner) {
            path = `software-partners/${this.selectedSoftwarePartner.id}`
          } else if (this.selectedLab) {
            path = `laboratories/${this.selectedLab.id}`
          }
        }
      }
      await this.$store.dispatch('optic/fetchOptics', path)
    },
    getStatusVariant(item) {
      if (item.deletedAt) return 'danger'
      else if (item.status == 'active') return 'success'
      else if (item.status == 'pending') return 'warning'
      else if (item.status == 'pending-authorization') return 'danger'
      else return 'warning'
    },
    getStatusText(item) {
      if (item.deletedAt) return this.$t('status.inactive')
      else if (item.status == 'active') return this.$t('status.active')
      else if (item.status == 'pending') return this.$t('status.pending')
      else if (item.status == 'pending-authorization')
        return this.$t('status.requested')
      else return this.$t('status.pending')
    },
    getActiveVariant(active) {
      if (active) return 'success'
      return 'danger'
    },
    getActiveText(active) {
      if (active) return 'SIM'
      return 'NÃO'
    },
  },

  setup() {
    const OPTIC_APP_STORE_MODULE_NAME = 'optic'
    const LAB_APP_STORE_MODULE_NAME = 'laboratory'
    const SP_APP_STORE_MODULE_NAME = 'software-partner'

    if (!store.hasModule(OPTIC_APP_STORE_MODULE_NAME)) {
      store.registerModule(OPTIC_APP_STORE_MODULE_NAME, opticStoreModule)
    }
    if (!store.hasModule(LAB_APP_STORE_MODULE_NAME)) {
      store.registerModule(LAB_APP_STORE_MODULE_NAME, labStoreModule)
    }
    if (!store.hasModule(SP_APP_STORE_MODULE_NAME)) {
      store.registerModule(SP_APP_STORE_MODULE_NAME, softwarePartnerStoreModule)
    }

    onUnmounted(
      () =>
        store.hasModule(OPTIC_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(OPTIC_APP_STORE_MODULE_NAME) &&
        store.hasModule(LAB_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(LAB_APP_STORE_MODULE_NAME) &&
        store.hasModule(SP_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SP_APP_STORE_MODULE_NAME),
    )

    const { softwarePartners, fetchSoftwarePartners } =
      useSoftwarePartnersList()

    if (store.state.auth.user.role === RoleEnum.SOFTWARE_ADMIN) {
      store.dispatch('laboratory/fetchLabs', store.state.auth.user.adminDocRef)
    } else if (store.state.auth.user.role === RoleEnum.SUPER_ADMIN) {
      fetchSoftwarePartners()
      store.dispatch('laboratory/fetchLabs', store.state.auth.user.adminDocRef)
      store.dispatch('optic/fetchOptics', store.state.auth.user.adminDocRef)
    } else {
      store.dispatch('optic/fetchOptics', store.state.auth.user.adminDocRef)
    }

    const {
      currentPage,
      isSortDirDesc,
      refOpticListTable,
      search,
      sortBy,
      tableColumns,
      optics,
      deleteOptic,
      restoreOptic,
      perPage,
      perPageOptions,
    } = useOpticsList()

    const { labs } = useLabsList()
    let selectedLab = ref(null)
    let selectedSoftwarePartner = ref(null)

    const { sendSignLink } = useAuthLink()

    return {
      labs,
      selectedLab,
      selectedSoftwarePartner,
      softwarePartners,
      fetchSoftwarePartners,

      currentPage,
      isSortDirDesc,
      refOpticListTable,
      search,
      sortBy,
      tableColumns,
      optics,
      deleteOptic,
      restoreOptic,
      perPage,
      perPageOptions,
      sendSignLink,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
